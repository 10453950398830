import { SvgIcon } from "@material-ui/core";
import { ReportProblemOutlined } from "@material-ui/icons";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ButtonTrip, LinkMailTrip, TitleTrip } from "./styles";

import { useGlobal } from "../../contexts/globalContext";
import { getSessionStorage } from "../../utils/getSessionStorage";
import { useHistory } from "react-router-dom";
import LeadManagementRepository from "../../repositories/leadRepository";

export default function PlanYourTrip() {
	const { showAlert, setLoading, sleep } = useGlobal();
	let history = useHistory();

	const [send, setSend] = React.useState(false);
	const [openLoader, setOpenLoader] = React.useState(false);
	const [name, setName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phone, setPhone] = React.useState("");
	const [details, setDetails] = React.useState("");

	const onSend = async () => {
		setSend(true);

		if (!name || name === "" || !email || email === "" || !phone || phone === "" || !details || details === "") {
			showAlert("error", "Preencha todos os campos obrigatórios", 2000);
			return;
		}

		setLoading(true);
		setOpenLoader(true);

		try {
			const repository = new LeadManagementRepository();
			await repository.create({
				name,
				email,
				phone,
				details,
				channel: "form",
			});
		} catch (error) {
			console.error(error);
			showAlert(
				"error",
				"Ops! Ocorreu um erro ao enviar sua solicitação. Tente novamente mais tarde ou entre em contato com o e-mail abaixo.",
				3000
			);
			setLoading(false);
			setOpenLoader(false);
			return;
		}

		setLoading(false);
		setOpenLoader(false);
		setSend(false);
		showAlert("info", "Sua solicitação foi enviada com sucesso! Em breve entraremos em contato.", 3000);
		await sleep(3000);
		history.push("/");
	};

	React.useEffect(() => {
		const storagedUser = getSessionStorage("@App:user");

		if (storagedUser) {
			setName(storagedUser.name);
			setEmail(storagedUser.email);
		}
	}, []);

	return (
		<>
			<Header />
			<Loader openLoader={openLoader} isSaving />
			<Grid container>
				<CssBaseline />
				<Grid item xs={false} sm={4} md={7} backgroundColor={"#F1F1F1"}>
					<Box
						sx={{
							my: 9,
							mx: 6,
							display: "flex",
							flexDirection: "column",
							gap: 3,
						}}
					>
						<Box>
							<TitleTrip>Tenha seu roteiro personalizado!</TitleTrip>
						</Box>
						<Box display={"flex"} justifyContent={"space-around"} flexDirection={"column"} gap={1}>
							<Typography fontSize={"18px"} fontWeight={"400"} color={"#161C44"}>
								Viajar é a soma de experiências que começam bem antes do embarque.
							</Typography>
							<Typography fontSize={"18px"} fontWeight={"400"} color={"#161C44"}>
								Pensando nisso, nossos consultores estão prontos para te ajudar a fazer a escolha perfeita, de acordo com suas preferências
								e necessidades.
							</Typography>
							<Typography fontSize={"18px"} fontWeight={"400"} color={"#161C44"}>
								Preencha o formulário, e em breve entraremos em contato com você
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<img src={"/planyourtrip.png"} width={"300px"} height={"300px"} alt={"Plan Your Trip"} />
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography fontSize={"25px"} fontWeight={"700"} color={"#161C44"}>
							Dados para o contato
						</Typography>
						<Box noValidate sx={{ mt: 1 }}>
							<FormInput
								margin="normal"
								required
								fullWidth
								id="name"
								label="Nome completo"
								name="name"
								placeholder="Preencha com seu nome completo"
								autoFocus
								value={name}
								onChange={(e) => setName(e.target.value)}
								error={send && (!name || name === "")}
								helperText={send && (!name || name === "") ? "Nome não pode ser vazio" : ""}
							/>
							<FormInput
								mask="(99) 99999-9999"
								margin="normal"
								required
								fullWidth
								id="phone"
								label="Celular"
								name="phone"
								placeholder="Preencha com seu telefone celular"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								error={send && (!phone || phone === "")}
								helperText={send && (!phone || phone === "") ? "Celular não pode ser vazio" : ""}
							/>
							<FormInput
								margin="normal"
								required
								fullWidth
								id="email"
								label="E-mail"
								name="email"
								placeholder="Preencha com seu e-mail"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								error={send && (!email || email === "")}
								helperText={send && (!email || email === "") ? "E-mail não pode ser vazio" : ""}
							/>
							<FormInput
								margin="normal"
								required
								fullWidth
								multiline
								rows="7"
								id="details"
								label="Conte-nos um pouco sobre o que deseja"
								name="details"
								placeholder="Informe sua cidade de origem, destino, data, quantidade de passageiros, entre outro itens."
								value={details}
								onChange={(e) => setDetails(e.target.value)}
								error={send && (!details || details === "")}
								helperText={send && (!details || details === "") ? "Detalhes da viagem não pode ser vazio" : ""}
							/>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<Typography fontSize={"18px"} fontWeight={"600"} color={"#828282"}>
									* Itens cujo preenchimento é obrigatório.
								</Typography>
								<ButtonTrip onClick={onSend}>Enviar</ButtonTrip>
							</Box>

							<Box
								mt={4}
								sx={{
									display: "flex",
									flexDirection: "row",
									gap: 2,
									justifyContent: "space-around",
									alignItems: "center",
								}}
							>
								<SvgIcon component={ReportProblemOutlined} color="#FD665E" fill="#FD665E" />
								<Typography fontSize={"14px"} fontWeight={"400"} color={"#828282"}>
									Todos os dados coletados no cadastro do formulário serão utilizados para identificação das ofertas ao cliente. Para mais
									informações sobre o tratamento dos dados pessoais, acesse nossa política de privacidade.
								</Typography>
							</Box>
							<Box
								mt={4}
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Typography fontSize={"16px"} fontWeight={"700"} color={"#5A5A5A"}>
									Se possui alguma dúvida, problemas com seu resgate ou sua viagem, Fale Conosco! <br />
									Envie um e-mail para nossa central de atendimento.
								</Typography>

								<LinkMailTrip href="mailto:faleconosco@destinoferias.com.br">faleconosco@destinoferias.com.br</LinkMailTrip>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Footer />
		</>
	);
}
