import React, { useState } from 'react';
import * as S from './styles';
import { withRouter } from 'react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';
import CarouselComponent from '../Carousel';
// import GradeIcon from '@material-ui/icons/Grade';
import PlaceIcon from '@material-ui/icons/Place';
import CheckIcon from '@material-ui/icons/Check';
import { useGlobal } from '../../contexts/globalContext';
import { useHistory } from 'react-router-dom';
// import ThemeButton from '../ThemeButton';
import FormInput from '../FormInput';
import ExpSubDetail from '../ExpSubDetail';
import InputCalendar from '../InputDate';
import CalendarPrimary from '../../assets/icons/CalendarPrimary.svg';
import ThemeButton from '../ThemeButton';
import RegisterModal from '../RegisterModal';

function ExperienceDetailsComponent({ experience, images, buscaAtual }) {
	const { user, showAlert } = useGlobal();
	const [adults, setAdults] = useState(1);
	const initialState = {
		datas: '',
	};
	const [state, setState] = useState(initialState);
	const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);
	const handleRegisterModal = () => setIsOpenRegisterModal((prev) => !prev);
	let history = useHistory();

	const onSubmit = () => {
		if (adults <= 0) {
			return showAlert('error', 'Quantidade de ingressos deve ser maior que 0.');
		}
		if (!state?.experienceDate?.dataIda) {
			return showAlert('error', 'Selecione uma data para sua experiência.');
		}

		const dataIdaArr = state?.experienceDate?.dataIda.split('/');
		const dataIdaAsExperienceDateFormatRequire = `${dataIdaArr[2]}-${dataIdaArr[1]}-${dataIdaArr[0]}`;

		sessionStorage.setItem(
			'@ExperienceSelected',
			JSON.stringify({
				experienceDate: {
					date: dataIdaAsExperienceDateFormatRequire,
					experienceId: experience.id,
					id: null,
					qtAvailable: 999,
					qtTotal: 999,
					time: null,
				},
				experience: experience,
				adults: parseInt(adults * experience.qtParticipants),
				childs: null,
			})
		);

		if (user?.guest || user?.travelVoucher) {
			return handleRegisterModal();
		}
		history.push('/busca/resumo');
	};

	return (
		<S.Wrapper>
			<Container>
				<Grid container>
					<Grid item xs={12} md={12} lg={8}>
						<S.CarouselContainer>
							<CarouselComponent buscaAtual={buscaAtual} images={images} />
						</S.CarouselContainer>
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<S.ExperienceName>
							<span>
								<S.Title>{experience?.name}</S.Title>
							</span>
							{/* {stars.map((star, index) => (
                <GradeIcon fontSize='small' key={index} />
              ))} */}
							<span className='location'>
								<PlaceIcon fontSize='small' />
								{experience?.localization}
							</span>
						</S.ExperienceName>
						<S.InformacoesUteis>
							<S.Title3>
								Selecione o número de Ingressos
								{experience?.continuous && ' e uma data para sua experiência'}.
							</S.Title3>
							<Typography>
								<b>Atenção</b>, o valor de cada ingresso é valido para{' '}
								{experience?.qtParticipants} pessoa(s)
							</Typography>
							{/* <S.CheckInOut>
                <strong>Check-in: </strong>
                {experience?.startDate !== undefined &&
                  experience?.startDate !== 'Invalid Date' &&
                  format(parseISO(experience?.startDate), 'dd/MM/yyyy HH:mm', {
                    locale: pt,
                  })}
                h
              </S.CheckInOut>
              <S.CheckInOut>
                <strong>Check-out: </strong>
                <span>
                  {experience?.endDate !== undefined &&
                    experience?.endDate !== 'Invalid Date' &&
                    format(parseISO(experience?.endDate), 'dd/MM/yyyy HH:mm', {
                      locale: pt,
                    })}
                  h
                </span>
              </S.CheckInOut> */}
							<S.Personas>
								<FormInput
									type='number'
									label='Ingressos'
									value={adults}
									onChange={(e) => e.target.value >= 0 && setAdults(e.target.value)}
								/>
								{/* <FormInput
                  type='number'
                  label='Crianças'
                  value={childs}
                  onChange={(e) =>
                    e.target.value >= 0 && setChilds(e.target.value)
                  } 
                />*/}
							</S.Personas>
							{/* {parseFloat(adults) + parseFloat(childs) === 0 && (
                <Typography variant='span' color='error'>
                  Por favor, selecione ao menos uma pessoa
                </Typography>
              )} */}
							{experience?.continuous && (
								<S.Fields>
									<InputCalendar
										reverseColor={true}
										icon={CalendarPrimary}
										label='Data'
										value={state?.experienceDate?.dataIda}
										onChange={(e) => setState({ ...state, experienceDate: e })}
										minStartDays={0}
									/>
									<ThemeButton onClick={() => onSubmit()} className='choose-experience'>
										Escolher data
									</ThemeButton>
								</S.Fields>
							)}
							<S.ValueInfos>
								{/* <div>
                  {experience?.originalPrice > 0 && (
                    <span className='old-value'>
                      De{' '}
                      <CurrencyFormat
                        fixedDecimalScale={true}
                        className='value'
                        value={
                          experience?.originalPrice *
                          (parseFloat(adults) + parseFloat(childs))
                        }
                        displayType={'text'}
                        decimalSeparator=','
                        decimalScale={2}
                        prefix={'R$ '}
                      />{' '}
                      por{' '}
                    </span>
                  )}
                  <span className='final-value'>
                    <CurrencyFormat
                      fixedDecimalScale={true}
                      value={
                        experience?.price *
                        (parseFloat(adults) + parseFloat(childs))
                      }
                      displayType={'text'}
                      decimalSeparator=','
                      decimalScale={2}
                      prefix={'R$ '}
                    />
                  </span>
                </div> */}
								{/* <ThemeButton
                  onClick={() => history.push('/busca/resumo')}
                  disabled={
                    parseFloat(adults) + parseFloat(childs) === 0 ? true : false
                  }
                  className='choose-hotel'
                >
                  Comprar
                </ThemeButton> */}
							</S.ValueInfos>
						</S.InformacoesUteis>
					</Grid>
					<Grid item xs={12}>
						<S.Title3>Descrição</S.Title3>
						<S.Descricao>
							{experience?.description}. {experience?.whatToExpect}
						</S.Descricao>
					</Grid>

					{!experience?.continuous && (
						<Grid item xs={12}>
							<S.Title3>Datas disponíveis</S.Title3>
							{experience?.experienceDates.map((experienceDate, index) => (
								<ExpSubDetail
									value={experience?.price * parseFloat(adults)}
									experience={experience}
									adults={adults}
									// childs={childs}
									totalValue={experience?.price}
									experienceDate={experienceDate}
									buscaAtual={buscaAtual}
									key={index}
								/>
							))}
						</Grid>
					)}
					<Grid item xs={12} lg={12}>
						<S.Title3>Outras informações</S.Title3>

						<S.Amenities>
							{experience?.whatIsIncluded && (
								<>
									<span>
										<CheckIcon fontSize='small' />
										<b>O que está incluso?</b>
									</span>
									<span className='child'>{experience.whatIsIncluded}</span>
								</>
							)}
							{experience?.whatIsNotIncluded && (
								<>
									<span>
										<CheckIcon fontSize='small' />
										<b>O que não está incluso?</b>
									</span>
									<span className='child'>{experience.whatIsNotIncluded}</span>
								</>
							)}
							{experience?.category && (
								<>
									<span>
										<CheckIcon fontSize='small' />
										<b>Categoria</b>
									</span>
									<span className='child'>{experience.category}</span>
								</>
							)}
							{experience?.freeCancel && (
								<>
									<span>
										<CheckIcon fontSize='small' />
										<b>Cancelamento Gratis?</b>
									</span>
									<span className='child'>Sim</span>
								</>
							)}
							{experience?.cancellationPolicy !== '' && (
								<>
									<span>
										<CheckIcon fontSize='small' />
										<b>Política de cancelamento</b>
									</span>
									<span className='child'>{experience?.cancellationPolicy}</span>
								</>
							)}
							{experience?.importantInfo !== '' && (
								<>
									<span>
										<CheckIcon fontSize='small' />
										<b>Informações importantes</b>
									</span>
									<span className='child'>{experience?.importantInfo}</span>
								</>
							)}
						</S.Amenities>
					</Grid>
				</Grid>
			</Container>
			<RegisterModal
				open={isOpenRegisterModal}
				onClose={handleRegisterModal}
				travelVoucherUser={user?.travelVoucher}
			/>
		</S.Wrapper>
	);
}

export default withRouter(ExperienceDetailsComponent);
