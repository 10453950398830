/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid, TextField, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import BookingsRepository from "../../repositories/bookingsRepository";

import * as S from "./styles";
import FlightTravelsCard from "./FlightTravelsCard";
import InputCalendar from "../InputDate";
import CalendarPrimary from "../../assets/icons/CalendarPrimary.svg";
import { useGlobal } from "../../contexts/globalContext";
import ExperienceTravelsCard from "./ExperienceTravelsCard";
import HotelTravelsCard from "./HotelTravelsCard";
import CarTravelsCard from "./CarTravelsCard";
import BusTravelsCard from "./BusTravelsCard";

const MyTravels = () => {
	const { user } = useGlobal();

	const [bookings, setBookings] = useState();
	const [bookingsFiltered, setBookingsFiltered] = useState([]);

	const [state, setState] = useState({
		name: "",
		dataInicio: "",
		dataFinal: "",
		situacao: "",
		type: "ALL",
	});
	const [coinAlias, setCoinAlias] = useState("Pontos");

	useEffect(() => {
		const bookingsRepository = new BookingsRepository();

		if (user.id) {
			bookingsRepository.getAllBookingsUser(user.id, state.type).then((snapshot) => {
				const res = snapshot.docs.map((i) => i.data());

				setBookings(res);
			});
		}
	}, [user.id, state.type]);

	useEffect(() => {
		let filtered = [];
		filtered = bookings;

		filtered = bookings?.filter(
			(x) =>
				x.passengers.filter((p) => (p.name?.toLowerCase() + " " + p.lastName?.toLowerCase()).indexOf(state.name?.toLowerCase()) > -1)
					.length > 0
		);

		if (state.dataInicio !== "") {
			filtered = filtered?.filter((x) => new Date(x.date) >= new Date(state.dataInicio.startDate));
		}

		if (state.dataFinal !== "") {
			filtered = filtered?.filter((x) => new Date(x.date) <= new Date(state.dataFinal.startDate));
		}

		if (state.situacao !== "") {
			if (state.situacao === "andamento") {
				filtered = filtered?.filter((x) => new Date(x.date) >= new Date());
			}
			if (state.situacao === "encerradas") {
				filtered = filtered?.filter((x) => new Date(x.date) < new Date());
			}
		}

		filtered = filtered && filtered.sort((a, b) => new Date(b.bookingDateStr) - new Date(a.bookingDateStr));
		// filtered = filtered && filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
		setBookingsFiltered(filtered);
	}, [state, bookings]);

	useEffect(() => {
		setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || "Pontos");
	}, [user?.campaign?.campaignConfig?.coinAlias]);
	return (
		<S.Wrapper>
			<Container>
				<Grid container spacing={3}>
					<Grid item xs={12} md={3}>
						{user.campaign?.campaignConfigLayout?.flight ||
							user.campaign?.campaignConfigLayout?.car ||
							user.campaign?.campaignConfigLayout?.hotel ||
							user.campaign?.campaignConfigLayout?.bus ||
							user.campaign?.campaignConfigLayout?.entertainment ||
							(user.campaign?.campaignConfigLayout?.experience && (
								<S.FilterGroup>
									<S.FilterTitle>Tipo</S.FilterTitle>
									<RadioGroup onChange={(e) => setState({ ...state, type: e.target.value })} aria-label="Tipo">
										{<FormControlLabel value="ALL" control={<Radio />} label="Tudo" checked={state.type === "ALL" ? true : false} />}
										{user.campaign?.campaignConfigLayout?.flight && (
											<FormControlLabel value="FLIGHT" control={<Radio />} label="Voos" checked={state.type === "FLIGHT" ? true : false} />
										)}
										{user.campaign?.campaignConfigLayout?.experience && (
											<FormControlLabel
												value="EXPERIENCE"
												control={<Radio />}
												label="Experiências"
												checked={state.type === "EXPERIENCE" ? true : false}
											/>
										)}
										{user.campaign?.campaignConfigLayout?.hotel && (
											<FormControlLabel value="HOTEL" control={<Radio />} label="Hoteis" checked={state.type === "HOTEL" ? true : false} />
										)}
										{user.campaign?.campaignConfigLayout?.car && (
											<FormControlLabel value="CAR" control={<Radio />} label="Carros" checked={state.type === "CAR" ? true : false} />
										)}
										{user.campaign?.campaignConfigLayout?.bus && (
											<FormControlLabel value="BUS" control={<Radio />} label="Rodoviários" checked={state.type === "BUS" ? true : false} />
										)}
									</RadioGroup>
								</S.FilterGroup>
							))}
						<S.FilterGroup>
							<S.FilterTitle>Nome</S.FilterTitle>
							<TextField
								id="outlined-basic"
								label="Buscar"
								variant="outlined"
								onChange={(e) => setState({ ...state, name: e.target.value })}
							/>
						</S.FilterGroup>
						<S.FilterGroup>
							<S.FilterTitle>Datas</S.FilterTitle>
							<InputCalendar
								icon={CalendarPrimary}
								label="Data de inicio"
								value={state?.dataInicio}
								onChange={(e) => setState({ ...state, dataInicio: e })}
								minStartDays={0}
							/>
							<InputCalendar
								icon={CalendarPrimary}
								label="Data final"
								value={state?.dataFinal}
								onChange={(e) => setState({ ...state, dataFinal: e })}
								minStartDays={0}
							/>
						</S.FilterGroup>
						<S.FilterGroup>
							<S.FilterTitle>Situação</S.FilterTitle>
							<RadioGroup onChange={(e) => setState({ ...state, situacao: e.target.value })} aria-label="Situacao">
								<FormControlLabel value="andamento" control={<Radio />} label="Em andamento" />
								<FormControlLabel value="encerradas" control={<Radio />} label="Encerradas" />
							</RadioGroup>
						</S.FilterGroup>
					</Grid>
					<Grid item xs={12} md={9}>
						{bookingsFiltered &&
							bookingsFiltered.map((booking, i) => {
								return (
									<>
										{booking.type === "FLIGHT" && (
											<FlightTravelsCard
												key={i}
												booking={booking}
												departureDate={booking?.departuredatetime}
												arrivalDate={booking?.arrivaldatetime}
												coinAlias={coinAlias}
											/>
										)}

										{booking.type === "EXPERIENCE" && <ExperienceTravelsCard key={i} booking={booking} coinAlias={coinAlias} />}

										{booking.type === "HOTEL" && <HotelTravelsCard key={i} booking={booking} coinAlias={coinAlias} />}

										{booking.type === "CAR" && <CarTravelsCard key={i} booking={booking} coinAlias={coinAlias} />}

										{booking.type === "BUS" && <BusTravelsCard key={i} booking={booking} coinAlias={coinAlias} />}
									</>
								);
							})}
					</Grid>
				</Grid>
			</Container>
		</S.Wrapper>
	);
};

export default withRouter(MyTravels);
