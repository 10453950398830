import * as S from "./styles";
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { pt } from "react-date-range/dist/locale";
import { Container, Grid, Tooltip } from "@material-ui/core";
import { addDays, addMonths, parse } from "date-fns";

import CarouselComponent from "../../components/Carousel";
import CounterButton from "../CounterButton";
import ThemeButton from "../../components/ThemeButton";
import { getSessionStorage } from "../../utils/getSessionStorage";
import Duration from "../../assets/icons/Duration.svg";
import Cancelation from "../../assets/icons/Cancelation.svg";
import Confirmation from "../../assets/icons/Confirmation.svg";
import Disponibility from "../../assets/icons/Disponibility.svg";
import Star from "../../assets/icons/Star.svg";
import Check from "../../assets/icons/Check.svg";
import Positive from "../../assets/icons/Positive.svg";
import Negative from "../../assets/icons/Negative.svg";
import Information from "../../assets/icons/Information.svg";
import EntertainmentRepository from "../../repositories/entertainmentRepository";

function EntertainmentDetails({ searchState, buscaAtual }) {
    const [activity, setActivity] = useState({});
    const [date, setDate] = useState(null);
    const [availabilityData, setAvailabilityData] = useState(null);

    const entertainmentRepo = new EntertainmentRepository();

    const handleCheckAvailability = async (activityKey, refToken) => {
        if (!activityKey) {
            console.error("Está faltando envio da key");
            return;
        }

        try {
            const availabilityResponse =
                await entertainmentRepo.checkAvailability(activityKey, refToken);
            if (availabilityResponse) {
                setAvailabilityData(availabilityResponse);
                console.log("Availability:", availabilityData);
            } else {
                console.error("Nenhum dado de disponibilidade retornado");
            }
        } catch (error) {
            console.error("Erro durante a checagem de disponibilidade:", error);
        }
    };

    const availableDatesFormatted = Array.isArray(activity?.avaiableDates)
        ? activity.avaiableDates
              .filter((date) => date)
              .map((date) => parse(date, "dd/MM/yyyy", new Date()))
        : [];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setActivity(getSessionStorage("@entertainmentSelected"));
    }, []);

    return (
        <S.Wrapper>
            <Container>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <S.Title>{activity.name}</S.Title>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <CarouselComponent
                            type={"entertainment-card"}
                            buscaAtual={buscaAtual}
                            images={
                                activity.tour &&
                                Array.isArray(activity.tour.images) &&
                                activity.tour.images.length > 0
                                    ? activity.tour.images.map(
                                          (image) => image.url
                                      )
                                    : [activity.pictureUrl] || []
                            }
                        />
                    </Grid>
                    <Grid container>
                        <S.GridFlexible
                            className="spacing top"
                            item
                            xs={12}
                            lg={8}
                        >
                            <S.AboutContainer className="about">
                                <span>Sobre a experiência</span>
                                <p className="text">
                                    {activity.descriptionAdditional}
                                </p>
                                <div className="tags">
                                    <div className="left">
                                        {activity.duration > 0 && (
                                            <div>
                                                <img
                                                    src={Duration}
                                                    alt="duração da experiência"
                                                />
                                                <div className="info">
                                                    <span className="top">
                                                        Duração da experiência
                                                    </span>
                                                    <span className="bottom">
                                                        {activity.duration}h
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <img
                                                src={Confirmation}
                                                alt="confirmação"
                                            />
                                            <div className="info">
                                                <span className="top">
                                                    Confirmação da experiência
                                                </span>
                                                <span className="bottom">
                                                    Imediata
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div>
                                            <img
                                                src={Cancelation}
                                                alt="cancelamento"
                                            />
                                            <div className="info">
                                                <span className="top">
                                                    Cancelamento{" "}
                                                </span>
                                                <span className="bottom">
                                                    Grátis até{" "}
                                                    {
                                                        activity.daysBeforeCancellationPolicy
                                                    }{" "}
                                                    dias antes
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <img
                                                src={Disponibility}
                                                alt="disponibilidade"
                                            />
                                            <div className="info">
                                                <span className="top">
                                                    Disponibilidade
                                                </span>
                                                <span className="bottom">
                                                    {activity.weekDays}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </S.AboutContainer>
                            <S.AboutContainer className="highlights">
                                <div className="title">
                                    <span>Destaques da experiência&nbsp;</span>
                                    <img src={Star} alt="estrela" />
                                </div>
                                <div className="highlights-grid">
                                    {activity.tour &&
                                        Array.isArray(
                                            activity.tour.inclusions
                                        ) &&
                                        activity.tour.inclusions
                                            .filter(
                                                (inclusion) =>
                                                    inclusion.type ===
                                                        "Destaques" &&
                                                    inclusion.id === 17
                                            )
                                            .map((inclusion, index) => (
                                                <div
                                                    key={index}
                                                    className="highlight-item"
                                                >
                                                    <img
                                                        src={Check}
                                                        alt="ícone check"
                                                    />
                                                    <p>
                                                        {inclusion.description}
                                                    </p>
                                                </div>
                                            ))}
                                </div>
                            </S.AboutContainer>
                            <S.AboutContainer className="included">
                                <span className="title">
                                    O que inclui e não inclui
                                </span>
                                <div className="list-container">
                                    <div className="includes">
                                        {activity.tour &&
                                            Array.isArray(
                                                activity.tour.inclusions
                                            ) &&
                                            activity.tour.inclusions
                                                .filter(
                                                    (inclusion) =>
                                                        inclusion.id === 18
                                                )
                                                .map((inclusion, index) => (
                                                    <div
                                                        key={index}
                                                        className="item"
                                                    >
                                                        <img
                                                            src={Positive}
                                                            alt="Incluído"
                                                        />
                                                        <span className="description">
                                                            &nbsp;
                                                            {
                                                                inclusion.description
                                                            }
                                                        </span>
                                                    </div>
                                                ))}
                                    </div>
                                    <div className="excludes">
                                        {activity.tour &&
                                            Array.isArray(
                                                activity.tour.inclusions
                                            ) &&
                                            activity.tour.inclusions
                                                .filter(
                                                    (inclusion) =>
                                                        inclusion.id === 19
                                                )
                                                .map((inclusion, index) => (
                                                    <div
                                                        key={index}
                                                        className="item"
                                                    >
                                                        <img
                                                            src={Negative}
                                                            alt="Não Incluído"
                                                        />
                                                        <span className="description">
                                                            &nbsp;
                                                            {
                                                                inclusion.description
                                                            }
                                                        </span>
                                                    </div>
                                                ))}
                                    </div>
                                </div>
                            </S.AboutContainer>
                            <S.AboutContainer className="box">
                                <h2 className="title">
                                    Prepare-se para que a sua experiência possa
                                    ser{" "}
                                    <span className="highlight">INCRÍVEL!</span>
                                </h2>
                                <p className="description">
                                    Confira algumas informações e cuidados
                                    essenciais para que você tenha uma
                                    experiência única do começo ao fim:
                                </p>
                                <div className="information-list">
                                    {activity.tour &&
                                        Array.isArray(
                                            activity.tour.inclusions
                                        ) &&
                                        activity.tour.inclusions
                                            .filter(
                                                (inclusion) =>
                                                    inclusion.id === 20
                                            )
                                            .map((inclusion, index) => (
                                                <div
                                                    key={index}
                                                    className="itens"
                                                >
                                                    <img
                                                        src={Information}
                                                        alt="Informação"
                                                    />
                                                    <span className="item">
                                                        &nbsp;
                                                        {inclusion.description}
                                                    </span>
                                                </div>
                                            ))}
                                </div>
                            </S.AboutContainer>
                        </S.GridFlexible>
                        <S.GridFlexible className="spacing" item xs={12} lg={4}>
                            <div className="calendar">
                                <S.Price>
                                    <div className="top">
                                        <span className="label1">
                                            A partir de:
                                        </span>
                                        <div>
                                            <span className="price">
                                                {activity.symbol}{" "}
                                                {activity.price}
                                            </span>
                                            <span className="label2">
                                                {" "}
                                                / por serviço
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mid">
                                        <Tooltip
                                            title="Preço válido para Privativo - Até 4 paxs"
                                            placement="top"
                                        >
                                            <span className="label3">
                                                ver condições
                                            </span>
                                        </Tooltip>
                                    </div>
                                </S.Price>
                                <S.Quantity>
                                    <div className="quantity-top">
                                        <span className="label1">
                                            Escolha a quantidade de
                                            pessoas&nbsp;
                                        </span>
                                        <span className="label2">
                                            (Obrigatório)
                                        </span>
                                    </div>
                                    <div className="bottom">
                                        <span className="label">Pessoa</span>
                                        <div>
                                            <CounterButton className="counter" />
                                        </div>
                                    </div>
                                </S.Quantity>
                                <S.DateCalendar
                                    onChange={(item) => setDate(item)}
                                    locale={pt}
                                    date={date}
                                    minDate={addDays(new Date(), 0)}
                                    maxDate={addMonths(new Date(), 12)}
                                    dayContentRenderer={(date) => {
                                        const isAvailable =
                                            availableDatesFormatted.some(
                                                (availableDate) =>
                                                    availableDate.getTime() ===
                                                    date.getTime()
                                            );
                                        return (
                                            <div
                                                style={{
                                                    opacity: isAvailable
                                                        ? 1
                                                        : 0.5, // Reduz a opacidade para os dias não disponíveis
                                                    pointerEvents: isAvailable
                                                        ? "auto"
                                                        : "none", // Bloqueia a interação com os dias não disponíveis
                                                }}
                                            >
                                                {date.getDate()}
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                            <ThemeButton
                                onClick={() => {
                                    handleCheckAvailability(activity?.key, activity?.refToken);
                                }}
                                className="choose-entertainment"
                            >
                                Verificar disponibilidade
                            </ThemeButton>
                        </S.GridFlexible>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <S.AvailabilityBox>
                            <span>
                                Antes de visualizar as opções desta experiência,
                                verifique a disponibilidade dela clicando no
                                botão acima.
                            </span>
                        </S.AvailabilityBox>
                    </Grid>
                </Grid>
            </Container>
        </S.Wrapper>
    );
}
export default withRouter(EntertainmentDetails);
