import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useGlobal } from "../../../contexts/globalContext";

import * as S from "./styles";
import EntertainmentCard from "./EntertainmentCard";
import { getSessionStorage } from "../../../utils/getSessionStorage";
import EntertainmentLoader from "./EntertainmentLoader";

const EntertainmentList = ({ filters, loading, error }) => {
  const { setLoading } = useGlobal();

  const filteredList = getSessionStorage("entertainmentSearch");

  const applyFilters = () => {
    if (!filteredList || !filteredList.activities) return [];

    return filteredList.activities.filter((activity) => {
      const matchesLocation =
        filters?.locations.length === 0 ||
        filters?.locations.includes(activity.locationFull);

      const matchesCategory =
        filters?.category.length === 0 ||
        filters?.category.some((category) =>
          activity.categoryList.includes(category)
        );

      const matchesWeekDays =
        filters?.weekDays.length === 0 ||
        filters?.weekDays.some((day) => {
          const days = [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
          ];

          const selectedDaysIndexes = filters.weekDays.map((selectedDay) =>
            days.indexOf(selectedDay)
          );

          const activityDays = activity.weekDaysList
            .split(";")
            .filter((day) => day !== "")
            .map((day) => parseInt(day, 10));

          return selectedDaysIndexes.some((selectedDayIndex) =>
            activityDays.includes(selectedDayIndex)
          );
        });

      return matchesLocation && matchesCategory && matchesWeekDays;
    });
  };

  const filteredActivities = applyFilters();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return loading ? (
    <Grid container>
      <Grid item sm={12} md={12} lg={12}>
        <S.Container>
          <EntertainmentLoader />
        </S.Container>
      </Grid>
    </Grid>
  ) : (
    <>
      <S.CounterText>
        <span>{filteredActivities.length} Resultados encontrados</span>
      </S.CounterText>
      {filteredActivities.length > 0 &&
        filteredActivities.map((activity) => (
          <S.Wrapper className="wrapperList" key={activity.id}>
            <EntertainmentCard activity={activity} />
          </S.Wrapper>
        ))}
    </>
  );
};

export default EntertainmentList;
