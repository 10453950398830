import React from "react";
import { Typography, ButtonBase } from "@material-ui/core";
import * as S from "./styles";
import { RightArrow } from "../../../../assets/icons";
import { useHistory } from "react-router-dom";

const SearchResults = ({
    activities,
    locations,
    submit,
    showResults,
    handleCheckLocationAvailability,
}) => {
    let history = useHistory();

    if (!showResults) {
        return null;
    }

    return (
        <S.Box>
            {locations?.length && activities?.length ? (
                <S.CustomTypography className="searchTitle" variant="h6">
                    "Nenhuma opção encontrada"
                </S.CustomTypography>
            ) : (
                ""
            )}

            {locations?.length ? (
                <>
                    <div
                        className="allResults"
                        onClick={() => submit("Todos os resultados")}
                    >
                        <div>
                            <Typography>Ver todos os resultados</Typography>
                        </div>
                        <S.StyledArrowForwardIcon className="allResultsIcon" />
                    </div>
                </>
            ) : activities?.length ? (
                <>
                    <div
                        className="allResults"
                        onClick={() => submit("Todos os resultados")}
                    >
                        <div>
                            <Typography>Ver todos os resultados</Typography>
                        </div>
                        <S.StyledArrowForwardIcon className="allResultsIcon" />
                    </div>
                </>
            ) : (
                <></>
            )}

            {locations?.length ? (
                <>
                    <Typography className="searchTitle">Locais</Typography>
                    {[
                        ...new Map(
                            locations?.map((location) => [
                                location.location,
                                location,
                            ])
                        ).values(),
                    ].map((location) => (
                        <div key={location.id}>
                            <Typography
                                onClick={() =>
                                    handleCheckLocationAvailability(
                                        location.routeDestiny,
                                        location.name
                                    )
                                }
                                className="foundCities"
                            >
                                <RightArrow className="rightArrow" />{" "}
                                {location.location}
                            </Typography>
                        </div>
                    ))}
                </>
            ) : activities?.length ? (
                <>
                    <Typography className="searchTitle">Locais</Typography>
                    {[
                        ...new Map(
                            activities?.map((activity) => [
                                activity.locationFull,
                                activity,
                            ])
                        ).values(),
                    ].map((activity) => (
                        <div key={activity.id}>
                            <Typography
                                onClick={() =>
                                    handleCheckLocationAvailability(
                                        activity.routeCities,
                                        activity.locationFull
                                    )
                                }
                                className="foundCities"
                            >
                                <RightArrow className="rightArrow" />{" "}
                                {activity.locationFull}
                            </Typography>
                        </div>
                    ))}
                </>
            ) : (
                <></>
            )}

            {activities?.length > 0 && (
                <>
                    <Typography className="searchTitle">Atividades</Typography>
                    {activities.slice(0, 4).map((activity) => (
                        <div
                            className="card"
                            key={activity.id}
                            onClick={() => {
                                submit(activity.location);
                                sessionStorage.setItem(
                                    "@entertainmentSelected",
                                    JSON.stringify(activity)
                                );
                                history.push("/busca/entertainment-details");
                            }}
                        >
                            <ButtonBase className="cardMedia">
                                <img
                                    alt={activity.name}
                                    src={activity.pictureUrl}
                                    className="cardImage"
                                />
                            </ButtonBase>
                            <div className="cardContent">
                                <Typography className="cardTitle">
                                    {activity.name.length > 90
                                        ? `${activity.name.substring(0, 80)}...`
                                        : activity.name}
                                </Typography>
                                <Typography className="cardCity">
                                    {activity.location}
                                </Typography>
                                <Typography className="cardPrice">
                                    {activity.price}
                                </Typography>
                            </div>
                            <S.StyledArrowForwardIcon />
                        </div>
                    ))}
                </>
            )}
        </S.Box>
    );
};

export default SearchResults;
