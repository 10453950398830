import React, { useState } from "react";
import * as S from "./styles";
import { Grid } from "@material-ui/core";
import TimerIcon from "@mui/icons-material/Timer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useHistory } from "react-router-dom";
import cleanStorageBeforeSearch from "../../../../utils/cleanStorageBeforeSearch";

const EntertainmentCard = ({ activity }) => {
    let history = useHistory();
    const [showFullText, setShowFullText] = useState(false);

    const LIMITCHARS = 100;

    const isTitleLong = activity.name.length > LIMITCHARS;
    const displayedTitle = showFullText
        ? activity.name
        : activity.name.substring(0, LIMITCHARS);

    const isDescriptionLong = activity.description.length > LIMITCHARS;
    const displayedDescription = showFullText
        ? activity.description
        : activity.description.substring(0, LIMITCHARS);

    const toggleText = () => {
        setShowFullText((prev) => !prev);
    };

    return (
        <S.CardContent className="cardContent">
            <Grid container className="container_img">
                <S.GridItemFlex item className="item_img">
                    <img
                        className="img"
                        src={activity.pictureUrl}
                        alt={activity.name}
                        onClick={() => {
                            cleanStorageBeforeSearch();
                            sessionStorage.setItem(
                                "@entertainmentSelected",
                                JSON.stringify(activity)
                            );
                            history.push("/busca/entertainment-details");
                        }}
                    />
                </S.GridItemFlex>
            </Grid>
            <Grid container direction="row" className="container_details">
                <S.GridItemFlex item className="top">
                    <span
                        className="title"
                        onClick={() => {
                            cleanStorageBeforeSearch();
                            sessionStorage.setItem(
                                "@entertainmentSelected",
                                JSON.stringify(activity)
                            );
                            history.push("/busca/entertainment-details");
                        }}
                    >
                        {displayedTitle}
                        {isTitleLong && (
                            <span className="plus">
                                {showFullText ? "" : "..."}
                            </span>
                        )}
                    </span>
                    <span className="type">
                        {activity.categoryList
                            .split(";")
                            .map((category) =>
                                category.trim().split(",")[1]?.trim()
                            )
                            .filter((categoryName) => categoryName)
                            .join(", ")}
                    </span>
                    <span className="location">{activity.location}</span>
                    <div className="separator">
                        <span className="cancelation">Cancelamento grátis</span>
                    </div>
                </S.GridItemFlex>
                <S.GridItemFlex item className="mid">
                    <span className="description">
                        {displayedDescription}
                        {isDescriptionLong && (
                            <span className="plus" onClick={toggleText}>
                                {showFullText ? " ver menos" : "... ver mais"}
                            </span>
                        )}
                    </span>
                </S.GridItemFlex>
                <S.GridItemFlex item className="bottom">
                    <div className="group">
                        <div className="left">
                            <div className="weekDays">
                                <TimerIcon
                                    className="icon"
                                    alt="weekDays-icon"
                                />
                                <span className="text">
                                    {activity.weekDays}
                                </span>
                                <span className="text" hidden>
                                    {activity.weekDaysList}
                                </span>
                            </div>
                            <div className="duration">
                                {activity.duration > 0 ? (
                                    <CalendarMonthIcon
                                        className="icon"
                                        alt="calendar-icon"
                                    />
                                ) : (
                                    ""
                                )}
                                <span className="text">
                                    {activity.duration > 0
                                        ? "Duração: " + activity.duration + "h"
                                        : ""}
                                </span>
                            </div>
                        </div>
                        <span className="price_label">A partir de</span>
                        <span className="price_text">
                            {activity.symbol} {activity.price}
                        </span>
                    </div>
                </S.GridItemFlex>
            </Grid>
        </S.CardContent>
    );
};

export default EntertainmentCard;
