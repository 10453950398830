import React from "react";

import * as S from "./styles";
import { Grid } from "@material-ui/core";
import { parseISO, format } from "date-fns";
import { Link } from "react-router-dom";

import BusLine from "../../../../assets/icons/BusLine.svg";

const BusCard = ({ busRoute, selected, setSelected, searchState }) => {
	const priceAmount = busRoute.pointsValue;
	return (
		<S.Wrapper>
			<S.CardContent>
				<Grid container className="container">
					{setSelected && (
						<S.GridItemFlex className="select-option" item lg={1} md={1} sm={1} xs={1}>
							<input
								className="radio"
								type="radio"
								checked={selected?.key === busRoute.key ? true : false}
								onChange={() => setSelected(busRoute)}
							/>
						</S.GridItemFlex>
					)}
					<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
						<div className="company-logo">
							<img src={busRoute.company.logo} alt={busRoute.company.name} />
						</div>
					</S.GridItemFlex>
					<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
						<span className="departure-date">{format(parseISO(busRoute.departure), "HH:mm")}</span>
						<span className="departure-city">{busRoute.origin}</span>
					</S.GridItemFlex>
					<S.GridItemFlex className="bus-line" item lg={2} md={2} sm={2} xs={2}>
						<img alt="departure-bus" src={BusLine} />
						<span className="duracao">{busRoute.duration}</span>
						<span className="duracao">Duração</span>
					</S.GridItemFlex>
					<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
						<span className="arrival-date">{format(parseISO(busRoute.arrival), "HH:mm")}</span>
						<span className="arrival-city">{busRoute.destination}</span>
					</S.GridItemFlex>
					<S.GridItemFlex className="points-class" item lg={2} md={2} sm={2} xs={2}>
						<span>{busRoute.company.type}</span>
					</S.GridItemFlex>
					{setSelected ? (
						<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
							<span className="points-amount">
								{priceAmount.toLocaleString("pt-br", {
									minimumFractionDigits: 0,
									maximumFractionDigits: 0,
								})}
								{" Pts"}
							</span>
							<span className="points-person">
								{busRoute.occupancy} pessoa{busRoute.occupancy > 1 ? "s" : ""}
							</span>
						</S.GridItemFlex>
					) : (
						<S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
							<Link className="alterar" to="/busca/resultado-pesquisa">
								Alterar
							</Link>
						</S.GridItemFlex>
					)}
				</Grid>
			</S.CardContent>
		</S.Wrapper>
	);
};

export default BusCard;
