import { Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
import { Calendar } from "react-date-range";
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaignColors = getCampaignColors();

export const Wrapper = styled.div`
    ${({ theme }) => css`
        background-color: #f2f2f2;
        font-family: "Poppins";
        margin-bottom: 50px;
        width: 100% !important;

        .spacing {
            margin-top: 50px;
        }

        .calendar {
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            background-color: #fff;
            font-family: "Poppins";
            width: 100%;
            height: 550px;
            border-radius: 4px;
            box-shadow: 0px 1px 4px #15223214;

            .top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .price {
                    color: ${theme.palette.secondary.main};
                    font-size: 22px;
                    font-weight: 700;
                    height: 26px;
                    line-height: 26px;
                }

                .label1 {
                    font-size: 15px;
                    font-weight: 600;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }

                .label2 {
                    color: #333;
                    font-size: 12px;
                    font-weight: 500;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }
            }

            .mid {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                justify-content: flex-end;

                .label3 {
                    color: #555;
                    font-size: 12px;
                    font-weight: 500;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }
            }

            .quantity-top {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                position: relative;
                left: 10px;

                .label1 {
                    color: #333;
                    font-size: 14px;
                    font-weight: 600;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }

                .label2 {
                    color: ${theme.palette.secondary.main};
                    font-size: 12px;
                    font-weight: 400;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }
            }

            .bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .label {
                    color: #333;
                    font-size: 14px;
                    font-weight: 600;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }

                .counter {
                    color: #333;
                    font-size: 16px;
                    font-weight: 700;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                    position: relative;
                    left: -30px;
                }
            }
        }

        .about {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
            margin-right: 50px;

            .text {
                font-size: 14px;
                font-weight: 300;
                line-height: 22px;
                color: #797979;
                margin-right: 50px;
            }

            .tags {
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-right: 50px;
                height: 200px;
                width: 100%;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-color: #d7d7d7;

                img {
                    width: 45px;
                    height: 45px;
                    margin-right: 20px;
                }

                .left,
                .right {
                    display: flex;
                    flex-direction: column;
                    width: 48%;
                }

                .left div,
                .right div {
                    display: flex;
                    align-items: space-around;
                    margin-top: 7px;
                    margin-bottom: 7px;

                    .info {
                        flex-direction: column;
                        align-items: flex-start;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0px;
                        margin-bottom: 0px;

                        .top {
                            color: ${theme.palette.secondary.main};
                            font-size: 14px;
                            font-weight: 500;
                            height: 18px;
                            line-height: 22px;
                            white-space: nowrap;
                            letter-spacing: 0.1px;
                        }

                        .bottom {
                            color: #555;
                            font-size: 13px;
                            font-weight: 400;
                            height: 22px;
                            line-height: 22px;
                            white-space: wrap;
                            letter-spacing: 0px;
                        }
                    }
                }

                .left div span,
                .right div span {
                    display: inline-block;
                }
            }
        }

        .highlights {
            margin-bottom: 20px;
            margin-right: 50px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid;
            border-color: #d7d7d7;

            .title {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }

            .highlights-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                width: 100%;
                margin-bottom: 20px;
            }

            .highlight-item {
                display: flex;
                align-items: center;
            }

            .highlight-item img {
                margin-right: 10px;
            }

            .highlight-item p {
                width: auto;
                margin: 0;
            }
        }

        .included {
            margin-bottom: 20px;
            margin-right: 50px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }

            .description {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: #555;
                height: 100%;
            }

            .list-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
            }

            .includes,
            .excludes {
                width: 48%;
            }

            .item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 10px;
            }

            .item img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
            }
        }

        .box {
            background-color: #333;
            padding: 20px;
            border-radius: 8px;
            color: #fff;
            margin-bottom: 20px;
            margin-right: 50px;

            .title {
                font-size: 16px;
                font-weight: 400;
                color: #aaa;
                height: 28px;
                line-height: 28px;
                letter-spacing: 0.5px;
            }

            .highlight {
                color: ${theme.palette.secondary.main};
            }

            .description {
                font-size: 13px;
                font-weight: 300;
                margin-bottom: 20px;
                color: #aaa;
                height: 44px;
                line-height: 22px;
                letter-spacing: 0px;
            }

            .itens {
                display: flex;
                align-items: flex-start; /* Garante que a imagem fique no topo do texto */
                margin-bottom: 10px; /* Espaçamento entre itens */
                width: 100%;

                img {
                    margin-right: 10px; /* Espaço entre a imagem e o texto */
                    width: 24px; /* Tamanho fixo para a imagem */
                    height: 24px; /* Tamanho fixo para a imagem */
                    flex-shrink: 0; /* Impede que a imagem encolha */
                }

                .item {
                    color: #fff;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 22px;
                    margin-bottom: 20px;
                    flex-grow: 1; /* Garante que o texto ocupe todo o espaço disponível ao lado da imagem */
                    white-space: normal; /* Permite quebra de linha no texto */
                    word-wrap: break-word; /* Garante que palavras longas quebrem */
                }
            }
        }
    `}
`;

export const Title = styled.span`
    ${({ theme }) => css`
        font-size: 28px;
        font-weight: 800;
        color: #545454;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        margin-top: 50px;
    `}
`;

export const Price = styled.div`
    width: 98%;
    height: 70px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const Quantity = styled.div`
    width: 98%;
    height: 90px;
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .top {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }
`;

export const DateCalendar = styled(Calendar)`
  ${({ theme }) => css`
    width: 98%;
    background: #fff;
    border: 1px solid #d5d5d5; /* Borda do calendário */
    border-radius: 4px;

    .MuiInputBase-root {
      color: ${theme.palette.secondary.main};
    }

    .rdrMonth,
    .rdrMonthsVertical {
      position: relative;
      background: #fff; /* Fundo branco */
      width: 100%;
    }

    ${theme.breakpoints.down("sm")} {
      zoom: 77%;
    }

    /* Estilo do número do dia selecionado */
    .rdrSelected,
    .rdrStartEdge,
    .rdrEndEdge {
      background-color: ${theme.palette.secondary.main} !important;
      color: #fff !important; /* Cor do número selecionado */
      font-weight: 700 !important; /* Peso 700 */
      font-size: 22px !important; /* Tamanho 22px */
      border-radius: 0px !important; /* Sem bordas arredondadas */
      border: 4px solid ${theme.palette.secondary.main} !important;
    }

    /* Estilo dos números dos dias habilitados */
    .rdrDayNumber span {
      color: #333 !important; /* Cor cinza escuro */
      font-weight: 400; /* Peso normal */
      font-size: 16px; /* Tamanho padrão */
    }

    /* Estilo dos dias desabilitados */
    .rdrDayDisabled .rdrDayNumber span {
      color: #d3d3d3 !important; /* Cor cinza claro para dias desabilitados */
      font-weight: 400; /* Peso normal */
      font-size: 16px; /* Tamanho padrão */
    }

    /* Remover bordas e sombras do hover */
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      border: none;
      background: none;
    }

    /* Estilo dos dias quando o mouse passa por cima */
    .rdrDayHovered .rdrDayNumber span {
      background: none;
      border-radius: 0px;
    }
  `}
`;

export const GridFlexible = styled(Grid)``;

export const AboutContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 10px;

    span {
        font-size: 20px;
        font-weight: 600;
        height: 28px;
        line-height: 28px;
        color: #333;
    }
`;

export const AvailabilityBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    background-color: #f7f7f7;
    height: 150px;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    color: #999;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;
