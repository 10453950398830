import api from "../services/apiHub";
import getHeaders from "../utils/returnHeadersHub";
import deburr from "lodash/deburr";

export default class EntertainmentRepository {

    searchAutoComplete = async (term) => {
        const headers = getHeaders();
        const normalizedTerm = deburr(term.toLowerCase());

        try {
            const body = {
                filter: {
                    name: normalizedTerm,
                },
            };

            const response = await api.post(
                "/api/services/entertainment/auto-complete",
                body,
                headers
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching autocomplete results:", error);
            return [];
        }
    };

    checkLocationAvailability = async (routes) => {
        const headers = getHeaders();
    
        let selectedRoute = null;
        let type = "";
    
        routes.forEach((route) => {
            if (route.startsWith("city/")) {
                selectedRoute = route;
                type = "city";
            } else if (!selectedRoute && route.startsWith("state/")) {
                selectedRoute = route;
                type = "state";
            } else if (!selectedRoute && route.startsWith("airport/")) {
                selectedRoute = route;
                type = "airport";
            }
        });
    
        if (!selectedRoute) {
            console.error("Nenhuma rota válida foi identificada.");
            return;
        }
    
        const body = {
            filter: {
                route: selectedRoute,
                type: type,
            },
        };
    
        try {
            const response = await api.post(
                "/api/services/entertainment/search",
                body,
                headers
            );
            console.log("API response:", response.data);
            return response.data;
        } catch (error) {
            console.error(
                "Erro ao verificar a disponibilidade da localização:",
                error
            );
            return null;
        }
    };    

    checkAvailability = async (activityKey, refToken) => {
        const headers = getHeaders();
        const body = {
            filter: {
                key: activityKey,
            },
        };

        try {
            const response = await api.post(
                "/api/services/check-availability",
                body,
                headers,
                refToken
            );
            console.log("API response:", response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching availability:", error);
            return null;
        }
    };

    getSearchResults = async (term) => {
        const headers = getHeaders();
        const normalizedTerm = deburr(term.selectedCity.toLowerCase());

        try {
            const body = {
                filter: {
                    name: normalizedTerm,
                },
            };

            const response = await api.post(
                "/api/services/entertainment/search",
                body,
                headers
            );
            const { activities, locations } = response.data;

            return {
                data: {
                    activities,
                    locations,
                },
                headers,
            };
        } catch (error) {
            console.error("Error fetching search results:", error);
            return {
                data: {
                    activities: [],
                    locations: [],
                },
                headers,
            };
        }
    };
}
