import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import * as S from "./styles";

class CounterButton extends React.Component {
  state = { counter: 0 };

  handleIncrement = () => {
    this.setState((state) => ({ counter: state.counter + 1 }));
  };

  handleDecrement = () => {
    if (this.state.counter > 0) {
      this.setState((state) => ({ counter: state.counter - 1 }));
    }
  };

  render() {
    return (
      <ButtonGroup size="small" aria-label="small outlined button group">
        <S.DecrementButton
          onClick={this.handleDecrement}
          disabled={this.state.counter === 0}
        >
          -
        </S.DecrementButton>
        <S.CounterDisplay disabled>{this.state.counter}</S.CounterDisplay>
        <S.IncrementButton onClick={this.handleIncrement}>+</S.IncrementButton>
      </ButtonGroup>
    );
  }
}

export default CounterButton;
