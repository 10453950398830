import React, { useState, useEffect } from "react";
import { TextField, Container, makeStyles, Grid } from "@material-ui/core/";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import cleanStorageBeforeSearch from "../../../utils/cleanStorageBeforeSearch";
import { useGlobal } from "../../../contexts/globalContext";
import { getSessionStorage } from "../../../utils/getSessionStorage";
import { ThemeButton } from "../../ThemeButton/styles";
import SearchResults from "./SearchResults";
import EntertainmentRepository from "../../../repositories/entertainmentRepository";
import { remove } from "diacritics";

// Estilos customizados
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "white",
    },
    opacity: 1,
    "& .MuiInputBase-input": {
      color: "#FFF",
    },
    "& label.Mui-focused": {
      color: "#FFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FFF",
      color: "#FFF",
    },
    "& .MuiFormLabel-root": {
      color: "#FFF",
      opacity: 0.45,
    },
    "& .MuiSelect-icon": {
      color: "#FFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FFF",
        color: "#FFF",
      },
      "&:hover fieldset": {
        borderColor: "#FFF",
        color: "#FFF",
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFF",
        color: "#FFF",
      },
      "&.Mui-error fieldset": {
        borderColor: "#FFF",
      },
    },
  },
}));

const EntertainmentSearchInputs = () => {
  const { showAlert } = useGlobal();
  let history = useHistory();
  const classes = useStyles();
  const initialState = {
    cidade: "",
  };
  const [state, setState] = useState(initialState);
  const [send, setSend] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [activities, setActivities] = useState([]);
  const [locations, setLocations] = useState([]);
  // const [locationAvailability, setLocationAvailability] = useState(null);

  const entertainmentRepo = new EntertainmentRepository();

  const inputSearch = async (value) => {
    setInputValue(value);
    if (value.length >= 3) {
      const results = await entertainmentRepo.searchAutoComplete(value);
      const normalizedValue = remove(value.toLowerCase());
  
      const filteredLocations = results?.locations?.filter((location) => {
        const name = location.name ? remove(location.name.toLowerCase()) : "";
        const nameSearch = location.nameSearch
          ? remove(location.nameSearch.toLowerCase())
          : "";
        const locationField = location.location
          ? remove(location.location.toLowerCase())
          : "";
        return (
          name.includes(normalizedValue) ||
          nameSearch.includes(normalizedValue) ||
          locationField.includes(normalizedValue)
        );
      });
  
      const filteredActivities = results?.activities?.filter((activity) => {
        const name = activity.name ? remove(activity.name.toLowerCase()) : "";
        const locationField = activity.location
          ? remove(activity.location.toLowerCase())
          : "";
        const locationFull = activity.locationFull
          ? remove(activity.locationFull.toLowerCase())
          : "";
        return (
          name.includes(normalizedValue) ||
          locationField.includes(normalizedValue) ||
          locationFull.includes(normalizedValue)
        );
      });
  
      setActivities(filteredActivities);
      setLocations(filteredLocations);
      setShowResults(true);
      setState({ ...state, cidade: value });
    } else {
      setActivities([]);
      setLocations([]);
      setShowResults(false);
      setState({ ...state, cidade: "" });
    }
  };  

  const handleSearchSubmit = (cidade) => {
    setSend(true);
    if (!state.cidade) {
      showAlert("error", "Por favor, preencha o que você deseja encontrar.");
    } else {
      cleanStorageBeforeSearch();
      sessionStorage.setItem(
        "entertainmentSearch",
        JSON.stringify({
          selectedCity: cidade,
          activities,
          locations,
        })
      );
      sessionStorage.setItem("buscaAtual", "Entretenimento");
      history.push("/busca/resultado-pesquisa");
    }
  };

  const handleCheckLocationAvailability = async (route, cidade) => {
    try {
      const availabilityResponse = await entertainmentRepo.checkLocationAvailability(route);
      if (availabilityResponse) {
        setActivities(availabilityResponse);
        cleanStorageBeforeSearch();
      sessionStorage.setItem(
        "entertainmentSearch",
        JSON.stringify({
          selectedCity: cidade,
          activities,
          locations,
        })
      );
      sessionStorage.setItem("buscaAtual", "Entretenimento");
      history.push("/busca/resultado-pesquisa");
      } else {
        console.error("Nenhum dado de disponibilidade retornado.");
      }
    } catch (error) {
      console.error("Erro ao verificar a disponibilidade da localização:", error);
    }
  };

  useEffect(() => {
    if (getSessionStorage("entertainmentSearch")) setState(getSessionStorage("entertainmentSearch"));
  }, []);

  return (
    <S.Wrapper>
      <Container>
        <div className="entretenimento-control">
          <Grid container spacing={1}>
            <Grid>
              <S.Title>O que deseja encontrar?</S.Title>
              <Grid item xs={12} sm={8} md={8} lg={6}>
                <Autocomplete
                  freeSolo
                  className={classes.root}
                  noOptionsText="Não encontrado"
                  options={[]}
                  getOptionLabel={(option) =>
                    option.name ? `${option.name}` : ""
                  }
                  inputValue={inputValue}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      inputSearch(value);
                    }
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      ...state,
                      cidade: newValue ? newValue.name : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Digite um destino, experiência ou interesse"
                      variant="outlined"
                      fullWidth
                      className={classes.root}
                      error={send && !state.cidade}
                    />
                  )}
                  value={state.cidade}
                  disableClearable
                />
              </Grid>
            </Grid>
            <Grid className="submit" item xs={12} sm={4} md={3} lg={2}>
              <ThemeButton onClick={handleSearchSubmit} className="submit1">
                Explorar
              </ThemeButton>
            </Grid>
          </Grid>
          <Grid className="results">
            <SearchResults
              cidade={state.cidade}
              activities={activities}
              locations={locations}
              handleCheckLocationAvailability={handleCheckLocationAvailability}
              submit={handleSearchSubmit}
              showResults={showResults}
            />
          </Grid>
        </div>
      </Container>
    </S.Wrapper>
  );
};

export default EntertainmentSearchInputs;
